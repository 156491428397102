import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'
import Helmet from 'react-helmet'
import { Location } from '@reach/router'

import mergeEdges from '../../utils/mergeEdges'

const imageShare = require('../../images/share.png')
const baseUrl = 'https://akasha.org'

interface ISeo {
  slug?: string
  custom?: {
    title: string
    description: string
    image: string
    url: string
  }
}

interface ISEONode {
  title: string
  description: string
  slug: string
}

interface INodes {
  seo: ISEONode[]
}

interface ISiteMetadata {
  site: {
    siteMetadata: {
      title: string
      description: string
    }
  }
  allContentYaml: {
    edges: {
      node: INodes
    }
  }
}

const SEO: React.FC<ISeo> = ({ custom }) => {
  return (
    <Location>
      {({ location }) => (
        <StaticQuery
          query={query}
          render={({
            site: { siteMetadata },
            allContentYaml,
          }: ISiteMetadata) => {
            let title: string | boolean = false
            let description: string | boolean = false
            let pageImage: string | boolean = false
            let pageUrl: string | boolean = `${baseUrl}${location.pathname}`

            const dataSeo: INodes | null = mergeEdges(allContentYaml)

            if (dataSeo !== null) {
              const found = dataSeo.seo.find(element => {
                const URL = location.pathname
                const fixURL = /\/$/.test(URL)
                  ? location.pathname.slice(0, -1)
                  : URL
                return element.slug === fixURL
              })
              if (found) {
                title = found.title
                description = found.description
              }
            }

            if (custom) {
              title = custom.title || title
              description = custom.description || description
              pageImage = custom.image || pageImage
              pageUrl = custom.url ? `${baseUrl}${custom.url}` : pageUrl
            }

            const pageTitle = title || siteMetadata.title
            const pageDescription = description || siteMetadata.description
            const image = `${baseUrl}${pageImage || imageShare}`

            return (
              <Helmet>
                <html lang="en" />

                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={pageDescription} />
                <meta name="twitter:url" content={pageUrl} />
                <meta name="twitter:image" content={image} />

                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={pageDescription} />
                <meta property="og:url" content={pageUrl} />
                <meta property="og:image" content={image} />
              </Helmet>
            )
          }}
        />
      )}
    </Location>
  )
}

const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
      }
    }
    allContentYaml {
      edges {
        node {
          seo {
            slug
            title
            description
          }
        }
      }
    }
  }
`

export default SEO
